import * as React from "react"
import { graphql } from 'gatsby'
import { PageWrapper } from "../components/PageWrapper"
import { Section } from "../components/Section"
import { StickyInfo } from "../components/StickyInfo"
import { NewsletterInline } from "../components/Newsletter"
import { StyledHeader } from "../components/Elements"

// markup
const IndexPage = ({
  data: {
    allMdx: { edges },
  },
}) => {
  let counter = 4;
  const posts = edges.reverse().map((post, index) => {
    var str = String(index + 1)
    var pad = "000"
    var ans = pad.substring(0, pad.length - str.length) + str

    if (counter === 4) counter = 0;
    counter += 1;

    return (
      <div>
        {counter === 4 && (
          <NewsletterInline />
        )}
        <Section key={post.node.frontmatter.slug} spacing={['mt-1']}>
          <StickyInfo left={`${ans} - Article`} href={'/blog/'+post.node.frontmatter.slug} />
          <StyledHeader>
              <a href={'/blog/'+post.node.frontmatter.slug}>
                <h1 className="display">
                  {post.node.frontmatter.title}
                </h1>
              </a>
              <div className="right font-size sm">
                {post.node.frontmatter.excerpt}
              </div>
          </StyledHeader>
        </Section>
      </div>
    )
  }).reverse()

  return (
    <PageWrapper seo={{
      title: 'Home'
    }}>
      <Section spacing={['mt-2']}>
        <StickyInfo left={`Introduction`} href={'/about/'} />
        <StyledHeader>
            <a href={'/about/'}>
              <h1 className="display">
                Design literally keeps us alive. Explore advanced articles about web design, ecommerce and more.
              </h1>
            </a>
            <div className="right font-size sm">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
            </div>
        </StyledHeader>
      </Section>
      {posts}
    </PageWrapper>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMdx {
      edges {
        node {
          id
          frontmatter {
            slug
            keywords
            thumbnail
            title,
            excerpt
          }
        }
      }
    }
  }
`